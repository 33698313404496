import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";

import Auth from './Auth';

ReactDOM.render(<>
  <Auth />
</>, document.getElementById("root"));
