import axios from 'axios';
import React from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import logo from "../logo.svg";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submit = this.submit.bind(this);
    this.username = React.createRef();
    this.password = React.createRef();
  }

  submit() {
    this.setState({error: null});
    axios.post('https://admin.beatquest.com/login', {
      username: this.username.current.value,
      password: this.password.current.value
    }).then((res) => {
      if (res.data.success) {
        localStorage.setItem('auth', res.data.token);
        for (const [key, value] of Object.entries(res.data.data)) {
          localStorage.setItem(key, value);
        }
        this.props.authenticate(res.data.token);
      } else {
        this.setState({error: res.data.message});
      }
    })
  }

  render() {
    return (
      <React.Fragment>

        <div className="text-center">
          <img src={logo} alt="BeatQuest" width="200" className="mb-4" />
        </div>

        <Card>
          <CardBody>
            <div className="text-center mt-4 mb-4">
              <h2>Welcome back!</h2>
              <p className="lead">Sign in to your account to continue</p>
            </div>
            <div className="m-sm-4">
              <Form>
                {this.state.error &&
                  <UncontrolledAlert color="danger" className="mb-4">
                    <div className="alert-icon">
                      <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                    </div>
                    <div className="alert-message">
                      {this.state.error}
                    </div>
                  </UncontrolledAlert>
                }
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    bsSize="lg"
                    type="text"
                    name="username"
                    placeholder="Enter your username"
                    innerRef={this.username}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    innerRef={this.password}
                  />
                </FormGroup>
                <div className="text-center mt-3">
                  <Button color="primary" size="lg" block onClick={this.submit}>
                    Sign in
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default SignIn;
