import React from "react";

import App from './App';
import Login from './app/Login';
import AuthLayout from "./layouts/Auth";
import Page404 from "./app/Page404";
import LogOut from "./app/Logout";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const token = localStorage.getItem('auth');
    if (token) this.setState({auth: token});
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login">
            {this.state.auth
              ? <Redirect to="/dashboard" />
              : <AuthLayout>
                <Login authenticate={token => {this.setState({auth: token})}} />
              </AuthLayout>
            }
          </Route>
          <Route path="/logout">
            <LogOut authenticate={() => {this.setState({auth: null})}} />
          </Route>
          <Route path="/dashboard">
            {this.state.auth
              ? <App />
              : <Redirect to="/login" />
            }
          </Route>
          <Route path="/" exact>
            {this.state.auth
              ? <Redirect to="/dashboard" />
              : <Redirect to="/login" />
            }
          </Route>
          <Route>
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Auth;
