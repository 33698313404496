import React from "react";
import { Container, Row, Col } from "reactstrap";

import LineChart from "./LineChart";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import HeaderSubtitle from "../../../components/HeaderSubtitle";

import logo from '../../../logo.svg';

const Default = () => (
  <Container fluid>
    <Header>
      <img src={logo} alt="Beat Quest" height="150" className="float-left mr-4" />
      <div className="pt-5">
        <HeaderTitle>Welcome back, {localStorage.getItem('name')}!</HeaderTitle>
        <HeaderSubtitle>
          Your Role: Beat Saber {localStorage.getItem('role')}
        </HeaderSubtitle>
      </div>
      <div className="clearfix"></div>
    </Header>

    <Row>
      <Col xl="12">
        <LineChart />
      </Col>
    </Row>
  </Container>
);

export default Default;
