import React from "react";
import { Redirect } from "react-router-dom";

class SignOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    localStorage.clear();
    this.setState({done: true});
    this.props.authenticate();
  }

  render() {
    if (this.state.done) {
      return (<Redirect to="/" />)
    } else {
      return <></>;
    }
  }
}

export default SignOut;
