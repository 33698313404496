import React from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

import logo from "../logo.svg";

const Page404 = () => (
  <div className="text-center">
    <img src={logo} alt="BeatQuest" width="200" className="mb-5" />
    <h1 className="display-1 font-weight-bold">404</h1>
    <p className="h1 mb-5">Page not found</p>
    <Link to="/">
      <Button color="primary" size="lg">
        Return home
      </Button>
    </Link>
  </div>
);

export default Page404;
